import React, { Fragment, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Wrapper,
  Square,
  Icon,
  Col,
  Link,
  Arrow,
  Create,
  Add,
  Run,
  CounterSeparation,
} from './styles'
import { Title, Text } from 'components/Typography'
import { colors } from 'theme/colors'
import TABS from 'constants/tabs'
import { routes } from 'router'

type Props = {
  create?: boolean
  onClick?: () => void
  type?: 'company' | 'customer' | 'notification' | 'admin'
  marginLeft?: number
  marginRight?: number
  marginTop?: number
  marginBottom?: number
  count?: number
  secondCount?: number
  isDefault?: boolean
  ImportExportCsv?: ReactElement
}

const createRoutes = {
  [TABS.COMPANY]: `${routes.edit.path}/new`,
  [TABS.NOTIFICATION]: routes.createNotification.path,
}

const Counter = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { type, create, count, isDefault, onClick, secondCount } = props

  return (
    <Wrapper {...props}>
      <Square>
        {type === TABS.NOTIFICATION && <Icon.Notification />}
        {type === TABS.CUSTOMER && <Icon.Customer />}
        {type === TABS.COMPANY && <Icon.Company />}
        {type === TABS.ADMIN && <Icon.Admin />}
      </Square>
      <Col>
        <Title margin={0} size={28}>
          {type === TABS.NOTIFICATION &&
            t(isDefault ? 'counter.notification.total' : 'counter.notification.title')}
          {type === TABS.COMPANY && t('counter.company')}
          {type === TABS.CUSTOMER && t('counter.customer')}
          {type === TABS.ADMIN && t('counter.admin')}
        </Title>
        {type === TABS.NOTIFICATION && !isDefault && (
          <Text margin={0} size={18}>
            {t('counter.notification.sub')}
          </Text>
        )}
        <Title
          margin={0}
          size={45}
          color={type === TABS.NOTIFICATION ? colors.mikadoYellow : colors.marine}
        >
          {count}
        </Title>

        {props?.ImportExportCsv}

        {type !== TABS.NOTIFICATION && !create && !onClick && (
          <Link onClick={() => push(routes.company.path)}>
            {t('counter.access')}
            <Arrow />
          </Link>
        )}
        {type !== TABS.ADMIN && type !== TABS.CUSTOMER && create && (
          <Create to={createRoutes[type]}>
            <Add />
            {type === TABS.COMPANY && t('counter.create.company')}
            {type === TABS.NOTIFICATION && t('counter.create.notification')}
          </Create>
        )}
        {type === TABS.ADMIN && (
          <Run onClick={() => onClick()}>
            <Add />
            {type === TABS.ADMIN && t('counter.create.admin')}
          </Run>
        )}
      </Col>
      {type === TABS.CUSTOMER && (
        <Fragment>
          <CounterSeparation />
          <Col>
            <Text margin={0} size={18}>
              {t('counter.including')}
            </Text>
            <Title margin={0} size={45} color={colors.mikadoYellow}>
              {secondCount}
            </Title>
            <Text margin={0} size={18}>
              {t('counter.min_one_device')}
            </Text>
          </Col>
        </Fragment>
      )}
    </Wrapper>
  )
}

export default Counter
