import React, { Fragment, ReactElement, useEffect } from 'react'
import API, { EXPORT, headers } from 'api'
import { useHistory } from 'react-router-dom'
import { routes } from 'router'

const DownloadExports = (): ReactElement => {
  const { push } = useHistory()

  const urlObject = new URL(window.location.href)
  const pathParts = urlObject.pathname.split('/')
  const id = pathParts[pathParts.length - 1]

  const downloadExports = async () => {
    try {
      const response = await fetch(API(`${EXPORT.CARDS}/${id}/download`), {
        method: 'GET',
        headers: headers(),
      })
      if (response.status === 200) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'export.csv'
        document.body.appendChild(a)
        a.click()
        window.close()
      } else if (response.status === 404) {
        push(`${routes.dashboard.path}?download-not-found`)
      } else if (response.status === 403 || response.status === 401) {
        push(`${routes.login.path}?download-unauthorized`)
      }
    } catch (error) {
      push(`${routes.dashboard.path}`)
    }
  }

  useEffect(() => {
    downloadExports()
  }, [])

  return <Fragment />
}

export default DownloadExports
